import { Component, OnInit } from '@angular/core';
import { CompanyNewsItem } from '../../apiService/classFiles/class.organizations';
import { ProdGenApi } from '../../apiService/prodgen.api';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAuthenticationService } from '../../BrowserAuthenticationService';
import { AppComponent } from '../../app.component';
import { TranslationPipe } from '../../pipes/TranslationPipe';
import { TranslationService } from '../../services/TranslationService';

@Component({
  selector: 'template-addonspanel',
  templateUrl: './addonspanel.component.html',
    styleUrls: ['./addonspanel.component.css'],
})
export class AddonspanelComponent implements OnInit {
  recommendedAddons: Array<CompanyNewsItem> = new Array<CompanyNewsItem>();
    canViewDownloadAddOns: boolean = true;

    constructor(private pinnacleService: ProdGenApi, private router: Router, private _sanitizer: DomSanitizer, private authenticationService: BrowserAuthenticationService, private transService: TranslationService) {
        if (authenticationService.AuthenticatePage() == true) {

      
        try {
          let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_download_view"));
          if (i != null) {

            this.canViewDownloadAddOns = i;

          }
        }
        catch (err) {
          console.log(err)
        }
      

    this.recommendedAddons = new Array<CompanyNewsItem>();

      if (window.navigator.userAgent.indexOf("Windows ") != -1) {
        this.pinnacleService.getCurrentUserPermissions().subscribe(permissions => {
            if ((permissions.administrator == true || permissions.authorContent == true || permissions.enrollUsers == true || permissions.viewReports == true) && this.isPinnacleLite == false) {
            let managementUtility = new CompanyNewsItem();
                 managementUtility.name = this.transService.getTranslationFileData("ADDONS.ManUtilName");
                 managementUtility.linkUrl = "https://pinnacle.blob.core.windows.net/web-portal/downloads/pinnacle.msi?" + ProdGenApi.getSessionUnique();
                 managementUtility.updateMsg = this.transService.getTranslationFileData("ADDONS.ManUrilUpdateMsg") ;
                 managementUtility.description = this.transService.getTranslationFileData("ADDONS.ManUtilDesc1") + " \n" + this.transService.getTranslationFileData("ADDONS.ManUtilDesc2");

                //managementUtility.name = "Pinnacle Management Utility Beta";
                //managementUtility.description = this.transService.getTranslationFileData("ADDONS.ManUtilDesc1") + " \n" + this.transService.getTranslationFileData("ADDONS.ManUtilDesc2");
                //managementUtility.linkUrl = "https://pinnacle.blob.core.windows.net/web-portal/downloads/pinnaclebeta.msi?" + ProdGenApi.getSessionUnique();
                //managementUtility.updateMsg = "Updated on 7/27/2021";

              this.recommendedAddons.push(managementUtility);
              try {
                  //localStorage.setItem()
              }
              catch (err) {

              }
            }
            
            
            // check for recommended addons
            //if (doesFontExist("Xenotron") == false) {
            let cmdlauncher = new CompanyNewsItem();
            cmdlauncher.name = this.transService.getTranslationFileData("ADDONS.CmdLauncherName");
            cmdlauncher.description = this.transService.getTranslationFileData("ADDONS.CmdLauncherDesc1") + "\n" + this.transService.getTranslationFileData("ADDONS.CmdLauncherDesc2");
            cmdlauncher.linkUrl = "https://pinnacle.blob.core.windows.net/web-portal/downloads/pinnacletools.msi?" + ProdGenApi.getSessionUnique();
            cmdlauncher.updateMsg = this.transService.getTranslationFileData("ADDONS.CmdLauncherUpdateMsg");

            this.recommendedAddons.push(cmdlauncher);
            //}



            if (permissions.administrator == true) {
                let deploymentGuide = new CompanyNewsItem();
                deploymentGuide.name = this.transService.getTranslationFileData("ADDONS.DeployGuideName");
                deploymentGuide.description = this.transService.getTranslationFileData("ADDONS.DeployGuideDesc1") + "\n" + this.transService.getTranslationFileData("ADDONS.DeployGuideDesc2");
                deploymentGuide.linkUrl = "https://pinnacle.blob.core.windows.net/web-portal/downloads/deploymentguide.pdf";

                this.recommendedAddons.push(deploymentGuide);
            }
            

        });
      }
    }
    }

    toolDwnldClick(p_Item: CompanyNewsItem) {
        //console.log("Test");
        if (p_Item.name == this.transService.getTranslationFileData("ADDONS.CmdLauncherName"))
        {
            //console.log("setting update");
            this.pinnacleService.UpdateCurrentUserToolDownloadDate().subscribe(res => {

            });
        }
        
    }

  ngOnInit() {
  }

    get isPinnacleLite(){
        return AppComponent.isPinnacleLite;
    }
}
