import { Component, OnInit, ChangeDetectorRef, AfterViewInit  } from '@angular/core';
import { WidgetComponent } from '../../widget/widget.component';
import { WidgetProperties, WidgetPropertyOptions } from '../../../apiService/classFiles/class.users';
import { ProdGenApi } from '../../../apiService/prodgen.api';
import { BrowserAuthenticationService } from '../../../BrowserAuthenticationService';
import { CompanyNewsItem } from '../../../apiService/classFiles/class.organizations';
import { TranslationService } from '../../../services/TranslationService';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-download-addons-widget',
  templateUrl: './download-addons-widget.component.html',
  styleUrls: ['./download-addons-widget.component.css']
})
export class DownloadAddonsWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
    static key = "DownloadAddonsWidgetComponent";

    widgetLoaded: boolean = false;

    recommendedAddons: Array<CompanyNewsItem> = new Array<CompanyNewsItem>();
    canViewDownloadAddOns: boolean = true;
    
    overlayDisplayClass = "widget-overlay-style-page";

    
    constructor(private pinnacleService: ProdGenApi, private changedetectorref: ChangeDetectorRef, private authenticationService: BrowserAuthenticationService, private transService: TranslationService) {
        // call the base Widget class
        super(pinnacleService, changedetectorref);

        //Widget Defaults
        this.widgetHeight = 4;
        this.widgetWidth = 2;
        this.widgetTitle = "Download Add-ons";

        this.widgetName = "Download Add-Ons"
        this.widgetDesc = "Download additional add-ons for Pinnacle.";
        //End Widget Defaults

        if (authenticationService.AuthenticatePage() == true) {


            try {
                let i = JSON.parse(localStorage.getItem(ProdGenApi.getCurrentTenantId() + "_download_view"));
                if (i != null) {

                    this.canViewDownloadAddOns = i;

                }
            }
            catch (err) {
                console.log(err)
            }


            this.recommendedAddons = new Array<CompanyNewsItem>();

            if (window.navigator.userAgent.indexOf("Windows ") != -1) {
                this.pinnacleService.getCurrentUserPermissions().subscribe(permissions => {
                    if ((permissions.administrator == true || permissions.authorContent == true || permissions.enrollUsers == true || permissions.viewReports == true) && this.isPinnacleLite == false) {
                        let managementUtility = new CompanyNewsItem();
                        managementUtility.name = this.transService.getTranslationFileData("ADDONS.ManUtilName");
                        managementUtility.description = this.transService.getTranslationFileData("ADDONS.ManUtilDesc1") + " \n" + this.transService.getTranslationFileData("ADDONS.ManUtilDesc2");
                        managementUtility.linkUrl = "https://pinnacle.blob.core.windows.net/web-portal/downloads/pinnacle.msi?" + ProdGenApi.getSessionUnique();
                        managementUtility.updateMsg = this.transService.getTranslationFileData("ADDONS.ManUrilUpdateMsg");

                        //managementUtility.name = "Pinnacle Management Utility Beta";
                        //managementUtility.description = this.transService.getTranslationFileData("ADDONS.ManUtilDesc1") + " \n" + this.transService.getTranslationFileData("ADDONS.ManUtilDesc2");
                        //managementUtility.linkUrl = "https://pinnacle.blob.core.windows.net/web-portal/downloads/pinnaclebeta.msi?" + ProdGenApi.getSessionUnique();
                        //managementUtility.updateMsg = "Updated on 7/27/2021";

                        this.recommendedAddons.push(managementUtility);
                        try {
                            //localStorage.setItem()
                        }
                        catch (err) {

                        }
                    }

                    
                        // check for recommended addons
                        //if (doesFontExist("Xenotron") == false) {
                        let cmdlauncher = new CompanyNewsItem();
                        cmdlauncher.name = this.transService.getTranslationFileData("ADDONS.CmdLauncherName");
                        cmdlauncher.description = this.transService.getTranslationFileData("ADDONS.CmdLauncherDesc1") + "\n" + this.transService.getTranslationFileData("ADDONS.CmdLauncherDesc2");
                        cmdlauncher.linkUrl = "https://pinnacle.blob.core.windows.net/web-portal/downloads/pinnacletools.msi?" + ProdGenApi.getSessionUnique();
                        cmdlauncher.updateMsg = this.transService.getTranslationFileData("ADDONS.CmdLauncherUpdateMsg");

                        this.recommendedAddons.push(cmdlauncher);
                        //}

                        if (permissions.administrator == true) {
                            let deploymentGuide = new CompanyNewsItem();
                            deploymentGuide.name = this.transService.getTranslationFileData("ADDONS.DeployGuideName");
                            deploymentGuide.description = this.transService.getTranslationFileData("ADDONS.DeployGuideDesc1") + "\n" + this.transService.getTranslationFileData("ADDONS.DeployGuideDesc2");
                            deploymentGuide.linkUrl = "https://pinnacle.blob.core.windows.net/web-portal/downloads/deploymentguide.pdf?" + ProdGenApi.getSessionUnique();

                            this.recommendedAddons.push(deploymentGuide);
                        }
                    

                });
            }
        }
    }

    toolDwnldClick(p_Item: CompanyNewsItem) {
        if (p_Item.name == this.transService.getTranslationFileData("ADDONS.CmdLauncherName")) {
            //console.log("setting update");
            this.pinnacleService.UpdateCurrentUserToolDownloadDate().subscribe(res => {

            });
        }

    }

    ngOnInit() {

        super.ngOnInit();

        try {
            //this.categoriesSubscription = JSON.parse(localStorage.getItem(this.currentUser.userId + "_categories_subscription"));
            this.displayType = JSON.parse(localStorage.getItem(this.widgetID + "_displayType"));
        }
        catch (err) {
        }

        // if it was not in storage, reert to default
        if (this.displayType == null) {
            this.displayType = "panel";
        }

        this.createProperties();
        this.Load();
    }

    ngAfterViewInit() {
        this.widgetContainer.incrementRenderedWidget();
    }

    //START WIDGET REQUIRED CALLS
    onPropsChanged(newprops: Array<WidgetProperties>, writeSettings: boolean = false, doresequence = true) {
        super.onPropsChanged(newprops, writeSettings, doresequence);

        //The rest of the onPropsChanged method is widget specific, following the template of the two IF statements above.
        

        if (writeSettings) {
            this.pinnacleService.setWidgetProperties(this.widgetContainer.containerInfo.containerInstanceId, this.widgetID, newprops, DownloadAddonsWidgetComponent.key).subscribe(res => {/*return stuff. might not need to do anything here*/ });
        }

        //this.widgetContainer.UpdateWidgetDimensions(this.widgetID, this.widgetWidth, this.widgetHeight);
        

        super.onPropsChangedLocalStorage();

        this.widgetLoaded = true;
        super.widgetArePropertiesLoaded();
        
    }



    Load() {
        super.Load();

        //The rest of the load function will be widget specific

    }

    createProperties() {

        super.createProperties();

        //The rest of the createProperties function will be widget specific, following the general format of the below commented code for each widget property


        super.createPropertiesDisplayType();
        super.createPropertiesWidgetTitle("Download Add-ons");

        super.createPropertiesMobileOptions();
    }

    //END WIDGET REQUIRED CALLS

    onWidgetMoved(x: number, y: number, selectedlayout: string = this.widgetContainer.selectedLayout) {
        super.onWidgetMoved(x, y, selectedlayout);
    }

    get isPinnacleLite() {
        return AppComponent.isPinnacleLite;
    }
}
